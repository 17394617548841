#events {
  background: url('../../assets/other/Event-Category-Card.png') center center;
  background-size: cover;
}

#give {
  background: url('../../assets/Home/Give-Category-Card.png') center center;
  background-size: cover;
}

#get-involved {
  background: url('../../assets/other/Get-Involved-Category-Card.png') center center;
  background-size: cover;
}
#testimonies {
  background: url('../../assets/other/Testimonies-Category-Card.png') center center;
  background-size: cover;
}
