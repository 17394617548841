.register-container {
  align-content: center;
  align-items: center;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 0.1vw;
  justify-content: center;
  overflow-x: hidden;
  position: relative;
  text-align: center;
}

.register-container::before {
  /* animation: animate-registerForm 15s linear infinite; */
  /* background: linear-gradient(#fff, rgb(106, 0, 255), #fff); */
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
}

/* @keyframes animate-registerForm {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
} */