:root{
  --light-blue : #04C3FF;
  --light-blue-shadow:  rgb(69 211 255 / 27%);
  --sea-green: #0ABEAD;
  --sea-green-dark: #0CBFAE;
  --sea-green-extra-dark: #09BEAD;
  --sea-green-shadow: rgba(11, 190, 173,0.4);
  --header-hamburger-background : rgba(11, 190, 173,0.8);
  --orange: #F57F17;
  --dark-orange: #FF5823;
  --orange-shadow: rgba(245, 127, 23,0.2);
  --white: #ffffff;
  --black: #333333;
  --disabled-btn: #09bead7a;
  --error-message:  #e44f4fd1;
  --toast-error: #e44f4ffa;
  --toast-shadow:  #9c9c9ce3;
  --toast-success: #0CBFAE;
  --loader-color: #0CBFAE;
  --shalom-lavendar: #c2b9f4;
}

#back-to-top-btn {
  background-color: black;
  border: none;
  border-radius: 0.3rem;
  bottom: 5vh;
  color: white;
  cursor: pointer;
  position: fixed;
  right: 2vw;
  z-index: 5;  
}

.custom-alert {
  background-color: rgb(255, 193, 193);
  border: 0.1rem solid red;
  box-sizing: border-box;
  border-radius: 0.5rem;
  bottom: 0;
  color: red;
  font-size: smaller;
  /* margin: 1.5rem auto 0; */
  padding: 0.2rem 0;
  position: absolute;
  width: 80%;
  z-index: 5;
}

.alert {
  background-color: rgb(255, 193, 193);
  box-sizing: border-box;
  border: 0.1rem solid red;
  border-radius: 0.5rem;
  /* bottom: 0; */
  color: red;
  font-size: smaller;
  margin: 0.2rem auto 0 auto;
  padding: 0.2rem 0.2rem;
  /* position: absolute; */
  width: 100%;
  z-index: 5;
}