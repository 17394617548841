@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  overflow-x: hidden;
}

button .load {
  width: 100%;
}

button .load img {
  color: green;
  margin-left: 0.5vw;
  width: 60px;
}

body,
html {
  overflow-x: hidden;
}

