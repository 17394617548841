.register-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.register-container .inputbox {
  display: flex;
  font-size: 1rem;
  justify-content: center;
  position: relative;
  width: 100%;
}

.register-container form input {
  background-color: rgb(82, 82, 82);
  border: none;
  border-radius: 0.5vw;
  box-sizing: border-box;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  left: 0;
  outline: none;
  position: absolute;
  width: 100%;
}

.register-container i:not(button > i) {
  color: #aaa;
  font-style: normal;
  left: 0;
  pointer-events: none;
  position: absolute;
  transition: 0.5s;
}

.register-container .inputbox input:focus:valid ~ i {
  transform: translateY(-0.5vw);
  font-size: 0.8rem;
  color: white;
}

/* .register-container .alert {
  background-color: rgb(155, 0, 0);
  border: 0.05rem solid white;
  border-radius: 0.5rem;
  bottom: 0;
  color: white;
  margin: auto auto;
  font-size: 0.9rem;
  padding: 0.2rem 0;
  position: absolute;
  width: 80%;
  z-index: 5;
} */

.register-container hr {
  margin-bottom: 1vh;
}

.register-container .formlinks {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.register-container .formlinks a {
  color: white;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  text-decoration: none;
}

.register-container .formlinks a:nth-child(2) {
  color: white;
  font-weight: 600;
  letter-spacing: 0.08rem;
}

/* .register-container button {
  background-color: white;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.01rem;
  margin-top: 25px;
  padding: 1vw 0;
  width: 100%;
}
 */