@import url('https://fonts.googleapis.com/css2?family=Sansita:ital@0;1&family=Share+Tech+Mono&display=swap');

.about-us-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  text-align: center;
  width: 100vw;
}


/* 
  background: url('../../assets/Aboutus/SM-Logo-Watermark.png');
 
  background-position: -120% 70%;
  background-repeat: repeat-y;
  background-size: 70%;


.about-us-container::before {
  background: url('../../assets/Aboutus/SM-Logo-Watermark.png');
  background-position: 215% 25%;
  background-repeat: repeat-y;
  background-size: 70%;
  content: '';
  height: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
} */

.mytext {
  text-align-last: center;
}